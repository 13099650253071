// This file is a port of some of the functions from the reducer/helper.js file
// It was converted to typescript, typed and refactored to be a bit easier to read.
import shopLabel from '../shop';
import LineItem from './types/LineItemTypes';
import { Fulfillment, Transaction } from './types/TransactionsTypes';

function mapFulfillmentItems(fulfillment: Fulfillment, lineItems: LineItem[]) {
  const referredItems: LineItem[] = [];

  if (!fulfillment.refersTo) return referredItems;

  fulfillment.refersTo.forEach((refersTo) => {
    const referredItem = lineItems.find(lineItem => lineItem.id === refersTo);
    if (referredItem) {
      referredItems.push(referredItem);
    }
  });

  return referredItems;
}

type MappedFulfillment = Fulfillment & { items: LineItem[] };

function addReferedToItemsToFulfillments(transaction: Transaction) {
  if (!transaction || !transaction.fulfillments || !transaction.lineItems) return [];

  const { lineItems } = transaction;

  const fulfillments: MappedFulfillment[] = transaction.fulfillments.map(fulfillment => ({
    ...fulfillment,
    items: mapFulfillmentItems(fulfillment, lineItems),
  }));
  return fulfillments;
}

function isTerminalAborted(order: any) {
  if (!order) return false;

  // Aborted through the app
  if (order.state === 'userAborted') return true;

  // Aborted through the terminal
  if (
    order.state === 'paymentFailed'
    && order.paymentResult?.failureCause === 'terminalAbort'
  ) return true;

  // Aborted through softpos
  if (
    order.state === 'paymentFailed'
    && order.paymentResult?.failureCause === 'userAborted'
  ) return true;
  return false;
}

function ageVerificationError(order: any) {
  if (!order || order.state !== 'paymentFailed') return false;
  return (
    order.paymentResult?.failureCause === 'ageVerificationNotSupportedByCard' ||
    order.paymentResult?.failureCause === 'ageVerificationFailed'
  );
}

function translationKeyOrderState(order: any) {
  if (!order) return '';
  if (isTerminalAborted(order)) {
    return 'orders.state.userAborted';
  }
  if (ageVerificationError(order)) {
    return `orders.state.${order.paymentResult.failureCause}`;
  }
  return `orders.state.${order.state}`;
}

interface CartDiscountInfo {
  id: LineItem['id'];
  trigger: LineItem;
  name: LineItem['name'];
  code: LineItem['discountReasonCode'];
  message: LineItem['discountReasonMessage'];
  discountPercentage: LineItem['discountPercentage'] | undefined;
  discountValue: LineItem['price'] | 0;
  portions: LineItem[];
  sum: number;
}

export function extractCartDiscounts(lineItems: LineItem[] | undefined) {
  if (!lineItems || !lineItems.length) return { items: [], cartDiscountInfo: [] };

  // find and extract any manual cart discounts.
  // cart discounts are stored as a line item with type manualDiscount.
  const manualCartDiscountIDs = lineItems
    .filter(item => (item.type === 'manualDiscount' && !item.refersTo && item.id))
    .map(item => item.id);

  // find portions for manual cart discounts.
  // these portions are needed since we cannot just reduce the value of a complete cart.
  // instead we need to reduce the value of each item (or at least multiple items) that is part
  // of the cart. So there are multiple individual disount entries in the cart. The ones we need
  // reference a manual cart discount (found in the previous step).
  const manualCartDiscountItemDiscounts = lineItems.filter(item => (
    item.type === 'discount' && manualCartDiscountIDs.includes(item.id)
  ));

  const cartDiscountInfo: CartDiscountInfo[] = [];

  // collect all manualCartDiscountItems
  manualCartDiscountIDs.forEach((manualCartDiscountID) => {
    // the line item that describes the current manual cart discount
    const manualCartDiscountTriggerForID = lineItems
      .find(item => item.id === manualCartDiscountID && item.type === 'manualDiscount');

    // all the individual item discounts that refer to the current manual cart discount
    const manualCartDiscountItemDiscountsForID = manualCartDiscountItemDiscounts
      .filter(item => item.id === manualCartDiscountID);

    const sumOfItemDiscounts = manualCartDiscountItemDiscountsForID
      .reduce((accumulator, itemDiscount) => accumulator + (itemDiscount?.totalPrice || 0), 0);

    // NOTE manualCartDiscountTriggerForID as an item from the lineItems array is always defined
    // since we only use IDs that we found in line items at the beginning of this function
    const mappedDiscount = {
      id: manualCartDiscountID,
      trigger: manualCartDiscountTriggerForID!,
      name: manualCartDiscountTriggerForID!.name,
      code: manualCartDiscountTriggerForID!.discountReasonCode,
      message: manualCartDiscountTriggerForID!.discountReasonMessage,
      discountPercentage: manualCartDiscountTriggerForID!.discountPercentage ?? undefined,
      discountValue: manualCartDiscountTriggerForID!.price ?? 0,
      portions: manualCartDiscountItemDiscountsForID,
      sum: sumOfItemDiscounts,
    };
    cartDiscountInfo.push(mappedDiscount);
  });


  const withoutCartManualDiscounts = lineItems
    // remove all discount items that refer to a manual cart discount or are of type manualDiscount
    .filter(item => !manualCartDiscountIDs.includes(item.id) && item.type !== 'manualDiscount');

  return { items: withoutCartManualDiscounts, cartDiscountInfo };
}

type RequiredRefersTo = Required<Pick<LineItem, 'refersTo'>>['refersTo'];

function extractReferringLineItems(lineItems: LineItem[]) {
  const referringItems: Record<RequiredRefersTo, LineItem[]> = {};
  const filteredItems: LineItem[] = [];
  let sum = 0;

  (lineItems || []).forEach((lineItem) => {
    if (lineItem.refersTo) {
      referringItems[lineItem.refersTo] = [...(referringItems[lineItem.refersTo] || []), lineItem];
    } else {
      sum += lineItem.amount;
      filteredItems.push(lineItem);
    }
  });

  return { items: filteredItems, referringItems, sum };
}

export function mapOrder(transaction: Transaction): MappedTransaction | {} {
  if (!transaction) return {};
  const {
    items: filteredLineItems,
    cartDiscountInfo,
  } = extractCartDiscounts(transaction.lineItems);
  const {
    items,
    referringItems,
    sum,
  } = extractReferringLineItems(filteredLineItems);

  const fulfillments = addReferedToItemsToFulfillments(transaction);

  let hasError = false;
  if (transaction.state === 'final' && fulfillments && fulfillments.length) {
    hasError = !!fulfillments.find((fulfillment => fulfillment.state !== 'processed'));
  }

  return Object.assign({}, transaction, {
    itemCount: sum,
    items,
    referringItems,
    cartDiscountInfo,
    shortID: transaction.id ? transaction.id.substring(0, 8) : transaction.id,
    shortAppUserID: transaction.appUser?.id ?
      transaction.appUser.id.substring(0, 8) : transaction.appUser?.id,
    shortCheckoutDeviceID: transaction.checkoutDevice?.id ?
      transaction.checkoutDevice.id.substring(0, 8) : transaction.checkoutDevice?.id,
    fulfillments,
    shop: Object.assign({}, transaction.shop, { label: shopLabel(transaction.shop) || '' }),
    stateTranslation: translationKeyOrderState(transaction),
    isTerminalAborted: isTerminalAborted(transaction),
    hasError,
  });
}

export type MappedTransaction = Transaction & {
  itemCount: number;
  items: LineItem[];
  referringItems: Record<RequiredRefersTo, LineItem[]>;
  cartDiscountInfo: CartDiscountInfo[];
  shortID: string;
  shortAppUserID: string | undefined;
  shortCheckoutDeviceID: string | undefined;
  fulfillments: MappedFulfillment[];
  shop: Transaction['shop'] & { label: string };
  stateTranslation: string;
  isTerminalAborted: boolean;
  hasError: boolean;
};

export function hasFulfillmentError(transaction: Transaction) {
  let hasError = false;

  const { state, fulfillments } = transaction;

  if (state === 'final' && fulfillments?.length) {
    hasError = !!fulfillments.find((fulfillment => fulfillment.state !== 'processed'));
  }
  return hasError;
}

export function getTotalSurcharge(transaction: Transaction): number {
  return transaction.payments?.reduce((totalSurcharge, payment) => (
    payment.surcharge ? totalSurcharge + payment.surcharge : totalSurcharge
  ), 0) || 0;
}
