import React from 'react';
import CheckedIcon from '@mui/icons-material/Done';
import UncheckedIcon from '@mui/icons-material/Block';
import {
  CustomFormProps,
  Form,
  useEnhancedForm,
} from '../form';
import useResourceTranslator from '../resource/useResourceTranslator';
// @ts-ignore
import PaperTable from '../components/PaperTable';
// @ts-ignore
import PaperTableRow from '../components/PaperTableRow';
import { ProjectConfig } from './useProjectConfigApi';
import LineBreakWrapper, { BreakMode } from '../components/LineBreakWrapper';

const DEFAULT_VALUES: ProjectConfig | {} = {
  companyAddress: {},
  texts: {},
};

export interface ViewProjectConfigFormProps extends CustomFormProps<ProjectConfig | {}> {
}

export default function ViewProjectConfigForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}: ViewProjectConfigFormProps) {
  const t = useResourceTranslator();
  const data = defaultValues as ProjectConfig;

  const {
    handleSubmit,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <PaperTable headline={t('projectConfig.general')}>
        <PaperTableRow
          label={t('projectConfig.name')}
          value={data.name}
        />
        <PaperTableRow
          label={t('projectConfig.displayName')}
          value={data.displayName || '-/-'}
        />
      </PaperTable>

      <PaperTable headline={t('projectConfig.company')}>
        <PaperTableRow
          label={t('projectConfig.companyName')}
          value={data.companyName || '-/-'}
        />
        <PaperTableRow
          label={t('projectConfig.companyAddress.street')}
          value={data.companyAddress?.street || '-/-'}
        />
        <PaperTableRow
          label={t('projectConfig.companyAddress.zip')}
          value={data.companyAddress?.zip || '-/-'}
        />
        <PaperTableRow
          label={t('projectConfig.companyAddress.city')}
          value={data.companyAddress?.city || '-/-'}
        />
        <PaperTableRow
          label={t('projectConfig.companyAddress.country')}
          value={data.companyAddress?.country || '-/-'}
        />
        <PaperTableRow
          label={t('projectConfig.companyAddress.phone')}
          value={data.companyAddress?.phone || '-/-'}
        />
        <PaperTableRow
          label={t('projectConfig.companyAddress.email')}
          value={data.companyAddress?.email || '-/-'}
        />
      </PaperTable>

      <PaperTable headline={t('projectConfig.finance')}>
        <PaperTableRow
          label={t('projectConfig.taxNumber')}
          value={data.taxNumber || '-/-'}
        />
        <PaperTableRow
          label={t('projectConfig.taxID')}
          value={data.taxID || '-/-'}
        />
        <PaperTableRow
          label={t('projectConfig.currency')}
          value={data.currency || '-/-'}
        />
        <PaperTableRow
          label={t('projectConfig.decimalDigits')}
          value={data.decimalDigits || '-/-'}
        />
        <PaperTableRow
          label={t('projectConfig.roundingMode.label')}
          value={data.roundingMode ? t(`projectConfig.roundingMode.${data.roundingMode}`) : '-/-'}
        />
        <PaperTableRow
          label={t('projectConfig.displayNetPrice')}
          value={data.displayNetPrice ? <CheckedIcon /> : <UncheckedIcon />}
        />
      </PaperTable>

      <PaperTable headline={t('projectConfig.texts.fieldset')}>
        <PaperTableRow
          label={(
            <LineBreakWrapper breakMode={BreakMode.DontBreak}>
              {t('projectConfig.texts.sepaMandateShort')}
            </LineBreakWrapper>
          )}
          value={(
            <LineBreakWrapper breakMode={BreakMode.FitAndNewline}>
              {data.texts?.sepaMandateShort || '-/-'}
            </LineBreakWrapper>
          )}
        />
        <PaperTableRow
          label={(
            <LineBreakWrapper breakMode={BreakMode.DontBreak}>
              {t('projectConfig.texts.sepaMandate')}
            </LineBreakWrapper>
          )}
          value={(
            <LineBreakWrapper breakMode={BreakMode.FitAndNewline}>
              {data.texts?.sepaMandate || '-/-'}
            </LineBreakWrapper>
          )}
        />
        <PaperTableRow
          label={(
            <LineBreakWrapper breakMode={BreakMode.DontBreak}>
              {t('projectConfig.texts.receiptContentPreFooter')}
            </LineBreakWrapper>
          )}
          value={(
            <LineBreakWrapper breakMode={BreakMode.NewlineOnly}>
              {data.texts?.receiptContentPreFooter || '-/-'}
            </LineBreakWrapper>
          )}
        />
      </PaperTable>
    </Form>
  );
}
