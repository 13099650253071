import React, { ReactNode } from 'react';

export enum BreakMode {
  FitAndNewline = 'fitAndNewline',
  NewlineOnly = 'newlineOnly',
  DontBreak = 'dontBreak',
}

const WHITE_SPACE_MAPPING: Record<BreakMode, string> = {
  [BreakMode.FitAndNewline]: 'pre-wrap',
  [BreakMode.NewlineOnly]: 'pre',
  [BreakMode.DontBreak]: 'nowrap',
};

const TEXT_WRAP_MAPPING: Record<BreakMode, string> = {
  [BreakMode.FitAndNewline]: 'wrap',
  [BreakMode.NewlineOnly]: 'nowrap',
  [BreakMode.DontBreak]: 'nowrap',
};

interface LineBreakWrapperProps {
  children: ReactNode;
  breakMode?: BreakMode;
}

/**
* This component allows the input to wrap at line break characters (e.g. `\n`)
*
* @param {ReactNode} children  The children to be rendered
*
* @param {BreakMode} breakMode Set if text should break to fit the parent container and at line
*                              break characters (FitAndNewline), only at line break characters
*                              (NewlineOnly) or never (DontBreak).
*/
export default function LineBreakWrapper({
  children,
  breakMode = BreakMode.FitAndNewline,
}: LineBreakWrapperProps) {
  return (
    <div
      style={{
        whiteSpace: WHITE_SPACE_MAPPING[breakMode],
        textWrap: TEXT_WRAP_MAPPING[breakMode],
      }}
    >
      {children}
    </div>
  );
}
