import React from 'react';
import { useTranslation } from 'react-i18next';
import { FailureCause } from './orderTypes';
import AdvancedStateIndicator, { State } from '../components/AdvancedStateIndicator';
import TextWithSubText from '../components/TextWithSubText';
import Payment, { PaymentState } from './types/PaymentTypes';
import { TransactionState } from './types/TransactionsTypes';

const PAYMENT_STATE_MAPPING: Record<PaymentState, State> = {
  [PaymentState.StateSuccessful]: State.Success,
  [PaymentState.StateTransferred]: State.Success,
  [PaymentState.StateFailed]: State.Error,
};

const FAILURE_CAUSE_MAPPING: Record<FailureCause, State> = {
  [FailureCause.UserAborted]: State.Warning,
  [FailureCause.AgeVerificationFailed]: State.Warning,
  [FailureCause.AgeVerificationNotSupportedByCard]: State.Warning,
  [FailureCause.TerminalAbort]: State.Error,
  [FailureCause.TerminalFailed]: State.Error,
  [FailureCause.TerminalErrored]: State.Error,
};

type TranslatableState = TransactionState | PaymentState | FailureCause | undefined;

export interface PaymentStateIndicatorProps {
  payment: Payment;
}

export default function PaymentStateIndicator({
  payment,
}: PaymentStateIndicatorProps) {
  const { t } = useTranslation();
  const stateT = (inputState: TranslatableState) => {
    if (!inputState) return '';
    return t(`orders.state.${inputState}`, { defaultValue: inputState });
  };

  const { state: paymentState, result: paymentResult } = payment;

  const failureCause = paymentResult?.failureCause as FailureCause;

  const computedPaymentState =
    (!!paymentState && PAYMENT_STATE_MAPPING[paymentState]) || State.Warning;
  const computedFailureCause = FAILURE_CAUSE_MAPPING[failureCause] || State.Warning;

  let computedIndicatorState: State = State.Warning;
  let computedIndicatorLabel: string = '';
  let computedIndicatorSubLabel: string = '';

  // NOTE this is just wrapped in a function so it stays somewhat readable
  const setIndicatorValues = () => {
    // all states are successful and there is are no failures => success
    if (computedPaymentState === State.Success && !failureCause) {
      computedIndicatorState = State.Success;
      computedIndicatorLabel = t('orders.state.successful');
      computedIndicatorSubLabel = '';
      return;
    }

    // having a state of success and a failure at the same time is logically
    // incorrect. This is fetched to prevent misleading labels.
    if (computedPaymentState === State.Success && !!failureCause) {
      computedIndicatorState = State.Warning;
      computedIndicatorLabel = stateT(failureCause);
      computedIndicatorSubLabel = '';
      return;
    }

    // the user aborted the payment => warning w/o the failed label
    if (failureCause === FailureCause.UserAborted) {
      computedIndicatorState = State.Warning;
      computedIndicatorLabel = t('orders.state.userAborted');
      computedIndicatorSubLabel = '';
      return;
    }

    // something went wrong but failureCause has an excuse => warning
    if (computedFailureCause === State.Warning) {
      computedIndicatorState = State.Warning;
      computedIndicatorLabel = stateT(paymentState);
      computedIndicatorSubLabel = stateT(failureCause);
      return;
    }

    // something went wrong, no excuses => error
    computedIndicatorState = State.Error;
    computedIndicatorLabel = stateT(paymentState);
    computedIndicatorSubLabel = stateT(failureCause);
  };
  setIndicatorValues();

  return (
    <AdvancedStateIndicator
      state={computedIndicatorState}
      label={(
        <TextWithSubText
          text={computedIndicatorLabel}
          subText={computedIndicatorSubLabel}
        />
      )}
      variant="outlined"
      sx={{ height: 'auto' }}
    />
  );
}
