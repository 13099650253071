export type Currency = string;

export type TaxPortions = Record<string, number>;

enum PaymentCardType {
  CardTypeUnknown = 'unknown',
  CardTypeVisa = 'visa',
  CardTypeCUP = 'cup',
  CardTypeDiners = 'diners',
  CardTypeECMCard = 'ecm_card',
  CardTypeJCB = 'jcb',
  CardTypeMasterCard = 'master_card',
  CardTypeMaestro = 'maestro',
  CardTypeAmericanExpress = 'american_express',
  CardTypeVPay = 'vpay',
  CardTypeVisaElektron = 'visa_elektron',
  CardTypeEuroELV = 'euro_elv',
  CardTypeGirocardELV = 'girocard_elv',
  CardTypeGermanDebitELV = 'german_debit_elv',
  CardTypeDankort = 'dankort',
  CardTypeGirocard = 'girocard',
  CardTypePostfinance = 'postfinance',
  CardTypeTWNT = 'twint',
  CardTypeBluecode = 'bluecode',
  CardTypeSnabblePay = 'snabble_pay',
  CardTypeVoucher = 'voucher',
}

enum PaymentMethod {
  MethodDeDirectDebit = 'deDirectDebit',
  MethodExternalBilling = 'externalBilling',
  MethodGatekeeperExternalBilling = 'gatekeeperExternalBilling',
  MethodCreditCardMastercard = 'creditCardMastercard',
  MethodCreditCardVisa = 'creditCardVisa',
  MethodCreditCardAmericanExpress = 'creditCardAmericanExpress',
  MethodGatekeeperTerminal = 'gatekeeperTerminal',
  MethodAutonomoTerminal = 'autonomoTerminal',
  MethodAutonomoOnlinePayment = 'autonomoOnlinePayment',
  MethodOutstandingPayment = 'outstandingPayment',
  MethodPaydirektOneKlick = 'paydirektOneKlick',
  MethodGooglePay = 'googlePay',
  MethodApplePay = 'applePay',
  MethodTwint = 'twint',
  MethodPostfinanceCard = 'postFinanceCard',
  MethodVoucher = 'voucher',
  MethodSmoothrTerminal = 'smoothrTerminal',
  MethodCash = 'cash',
}

export enum PaymentState {
  StateTransferred = 'transferred',
  StateSuccessful = 'successful',
  StateFailed = 'failed',
}

type PaymentCurrency = string;

export type PaymentInformation = Record<string, any>;

export default interface Payment {
  providerID?: string;
  cardType?: PaymentCardType;
  method?: PaymentMethod;
  state?: PaymentState;
  currency?: PaymentCurrency;
  paidAmount: number;
  information?: PaymentInformation;
  result?: PaymentInformation;
  surcharge: number;
}
