import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import moment from 'moment';
import Translate from '../components/i18n/Translate';
// @ts-ignore
import ColoredTableRow from '../components/ColoredTableRow';
// @ts-ignore
import PaperTable from '../components/PaperTable';
import { ApplicationState } from '../reducers/initialState';
// @ts-ignore
import { dateFormat } from '../components/LocaleDate';
import ContentLink from '../components/ContentLink';
import { buildLabelFromArray } from '../utils/stringUtils';
// @ts-ignore
import { formatPrice } from '../components/ProductFormatPrice';
import TextWithSubText from '../components/TextWithSubText';
import CheckoutDeviceInfo from './CheckoutDeviceInfo';
import PaymentCardIcon from '../components/PaymentCardIcon';
import { hasFulfillmentError } from './orderHelper';
import { Shop } from '../shop/useShopApi';
import { Transaction } from './types/TransactionsTypes';
import FilterIconSelector from '../components/FilterIconSelector';
import TransactionStateIndicator from './TransactionStateIndicator';

function splitDatetime(date: string, locale: string, part: 'date' | 'time') {
  const [datePart, ...timePart] = moment(date).format(dateFormat(locale, false)).split(' ');
  return part === 'date' ? datePart : timePart.join(' ');
}

export interface OrderListTableProps {
  values: Transaction[];
  onOrderQuickEdit?: (device: any) => void;
}

export default function OrderListTable({
  values,
  onOrderQuickEdit,
}: OrderListTableProps) {
  const { t, i18n } = useTranslation();
  const shops = useSelector<ApplicationState, Shop[]>(state => state.shops);
  const currency = useSelector<ApplicationState, string | null>(state => state.projectCurrency);

  const countLineItems = useCallback((order: Transaction) => {
    if (!order.lineItems?.length) return null;

    const filteredLineItems = order.lineItems.filter(item => !('refersTo' in item));
    return `${filteredLineItems.length} ${t('orders.items')}`;
  }, [t]);

  const TableRowEntries = useMemo(() => ({ rowData }: any) => {
    const transaction = rowData as Transaction;
    return (
      <ColoredTableRow>
        <TableCell
          align="left"
          style={{
            paddingRight: '10px',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          <Stack direction="row" gap={1} alignItems="center">
            <ContentLink
              to={`/${transaction.project}/orders/${transaction.id}`}
              onClick={(e: Event) => { e.preventDefault(); onOrderQuickEdit?.(rowData.id); }}
            >
              <span>
                {transaction.id?.slice(0, 8)}
              </span>
            </ContentLink>
            {hasFulfillmentError(transaction) && (
              <Tooltip title={t('orders.fulfillmentError')} placement="top">
                <div><ErrorOutline color="error" /></div>
              </Tooltip>
            )}
          </Stack>
        </TableCell>

        <TableCell align="left">
          <TextWithSubText
            text={splitDatetime(transaction.date, i18n.language, 'date')}
            subText={splitDatetime(transaction.date, i18n.language, 'time')}
            highlightSubText
          />
        </TableCell>

        <TableCell align="left">
          {transaction.shop.externalID} / {transaction.sequenceNumber}
        </TableCell>

        <TableCell align="left">
          <CheckoutDeviceInfo
            appUserID={transaction.appUser?.id}
            checkoutDeviceID={transaction.checkoutDevice?.id}
            checkoutDevice={transaction.checkoutDevice}
            project={transaction.project}
          />
        </TableCell>

        <TableCell align="right">
          <TextWithSubText
            text={formatPrice(transaction.price, currency) || '-/-'}
            subText={countLineItems(transaction)}
            containerStyle={{
              width: 'max-content',
              marginLeft: 'auto',
            }}
          />
        </TableCell>

        <TableCell align="center">
          {(transaction?.payments?.length === 1) && (
            <PaymentCardIcon
              paymentMethod={transaction.payments[0].method}
              paymentCardType={transaction.payments[0].cardType}
              project={transaction.project}
            />
          )}
          {(transaction?.payments && (transaction?.payments?.length > 1)) && (
            <FilterIconSelector number={transaction?.payments?.length} />
          )}
          {!transaction?.payments?.length && '-/-'}
        </TableCell>

        <TableCell align="left" style={{ paddingRight: '10px' }}>
          <TransactionStateIndicator transaction={transaction} />
        </TableCell>

        <TableCell align="left">
          <ContentLink to={`/${transaction.project}/shops/${transaction.shop.id}`}>
            <TextWithSubText
              text={shops.find(shop => shop.id === transaction.shop.id)?.name ?? ''}
              subText={buildLabelFromArray({ input: [transaction.shop.id, transaction.shop.externalID], separator: ' / ' })}
            />
          </ContentLink>
        </TableCell>

        <TableCell padding="checkbox" align="right">
          <Stack direction="row" spacing={1} flexGrow={1}>
            <Tooltip title={t('orders.detailsLink')} placement="top" arrow>
              <ContentLink to={`/${transaction.project}/orders/${transaction.id}`}>
                <IconButton size="small">
                  <VisibilityIcon />
                </IconButton>
              </ContentLink>
            </Tooltip>
          </Stack>
        </TableCell>
      </ColoredTableRow>
    );
  }, [countLineItems, currency, i18n.language, onOrderQuickEdit, shops, t]);

  const headCells = useMemo<string[]>(() => [
    t('orders.id'),
    t('orders.date'),
    t('orders.sequence'),
    t('orders.deviceID'),
    t('orders.cart'),
    t('orders.paymentMethod'),
    t('orders.state.label'),
    t('shop.itemName'),
    '',
  ], [t]);
  const headCellAlignments = useMemo<string[]>(() => [
    '',
    '',
    '',
    '',
    'right',
    '',
    '',
    '',
  ], []);

  const table = (
    (values?.length > 0) ?
      (
        <PaperTable tableHeader={headCells} headerAlignment={headCellAlignments}>
          {(values || []).map(transaction => (
            <TableRowEntries rowData={transaction} key={transaction.id} />
          ))}
        </PaperTable>
      )
      :
      (
        <div style={{ padding: '2rem' }}>
          <Typography><Translate id="orders.notFound" /></Typography>
        </div>
      )
  );

  return (
    <Paper elevation={4} >
      {table}
    </Paper>
  );
}
