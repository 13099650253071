import React from 'react';
import Typography from '@mui/material/Typography';
import {
  CustomFormProps,
  Form,
  useEnhancedForm,
} from '../form';
import { useResourceTranslator } from '../resource';
import { ShopPosConfig } from './useShopPosConfigApi';
// @ts-ignore
import PaperTable from '../components/PaperTable';
// @ts-ignore
import PaperTableRow from '../components/PaperTableRow';

const DEFAULT_VALUES: ShopPosConfig = {
  printers: [],
};

export interface ShopPosConfigFormProps extends CustomFormProps<ShopPosConfig> {
}

const ShopPosConfigForm = ({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}: ShopPosConfigFormProps) => {
  const t = useResourceTranslator();
  const data = defaultValues as ShopPosConfig;

  const {
    handleSubmit,
  } = useEnhancedForm({ defaultValues, errors });

  if (!data?.printers?.length) {
    return (
      <div style={{ padding: '2rem' }}>
        <Typography>{t('shop.noShopDevices')}</Typography>
      </div>
    );
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {data.printers?.map(printer => (
        <PaperTable
          key={printer.name}
          headline={t('form.fieldsets.printer')}
        >
          <PaperTableRow
            label={t('form.labels.printers.name')}
            value={printer.name}
          />
          <PaperTableRow
            label={t('form.labels.printers.address')}
            value={printer.address}
          />
          <PaperTableRow
            label={t('form.labels.printers.linePitch')}
            value={printer.linePitch}
          />
        </PaperTable>
      ))}
    </Form>
  );
};

export default ShopPosConfigForm;
