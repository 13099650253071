import { Permission, ProjectPermission, ProjectRole } from './useUserManagementApi';

// roles ordered by their access rights (ascending)
export const PROJECT_ROLE_HIERARCHY: Record<ProjectRole | 'none', number> = {
  none: -1,
  [ProjectRole.CASHIER]: 0,
  [ProjectRole.SUPERVISOR]: 1,
  [ProjectRole.PROJECT_GUEST]: 2,
  [ProjectRole.PROJECT_READER]: 3,
  [ProjectRole.PROJECT_ADMIN]: 4,
} as const;

export interface GetHighestLevelRoleProps {
  permissions: Permission[];
  projectFilter: string;
}

export default function getHighestLevelRole({
  permissions,
  projectFilter,
}: GetHighestLevelRoleProps): ProjectRole | null {
  if (!permissions?.length) return null;

  // filter out all realm and organization roles
  let projectPermissions = permissions.filter(r => (!!(r as any).project)) as ProjectPermission[];

  if (projectFilter) {
    projectPermissions = projectPermissions.filter(r => (r.project === projectFilter));
  }

  let hightestRole = 'none' as keyof typeof PROJECT_ROLE_HIERARCHY;
  projectPermissions.forEach((projectPermission) => {
    if (PROJECT_ROLE_HIERARCHY[projectPermission.role] > PROJECT_ROLE_HIERARCHY[hightestRole]) {
      hightestRole = projectPermission.role;
    }
  });

  if (hightestRole === 'none') return null;

  return hightestRole;
}
