import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toPairs from 'lodash/toPairs';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// @ts-ignore
import PaperTable from '../components/PaperTable';
// @ts-ignore
import PaperTableRow from '../components/PaperTableRow';
import { flattenObject } from '../utils/objectUtils';
import DownloadAsLink from '../components/DownloadAsLink';
import { Transaction } from './types/TransactionsTypes';
import PaymentStateIndicator from '../orders/PaymentStateIndicator';
// @ts-ignore
import PaymentMethodIcon from '../components/PaymentMethodIcon';
// @ts-ignore
import { formatPrice } from '../components/ProductFormatPrice';
import TextWithSubText from '../components/TextWithSubText';

interface OrderPaymentResultProps {
  transaction: Transaction;
}

export default function OrderPaymentResult({
  transaction,
}: OrderPaymentResultProps) {
  const { t } = useTranslation();
  const [expandedPayment, setExpandedPayment] = useState<number | null>(null);

  const renderTableRowList = useCallback((translatedKey: string, value: any[]) => (
    <PaperTableRow
      label={translatedKey}
      value={(
        <ul>
          {value.map(item => (
            <li>{JSON.stringify(item, null, 2)}</li>
          ))}
        </ul>
      )}
    />
  ), []);

  const renderDownloadLink = useCallback((name: string, receipt: string) => (
    (name && receipt) && <DownloadAsLink
      blob={new Blob([receipt], { type: 'text/plain;charset=utf-8' })}
      name={name}
      type="txt"
    />
  ), []);

  const renderTableRow = useCallback(([key, value]: [string, any]) => {
    // NOTE payment results change frequently. So a good fallback is needed for the translation.
    // For this the key itself is used but the dots are replaced with arrows.
    const tranlatedKey = t(`paymentResult.${key}`, {
      defaultValue: key.replace(/\./g, ' → '),
    });

    if (Array.isArray(value)) return renderTableRowList(tranlatedKey, value);
    if (key === 'customerReceipt') {
      return (
        <PaperTableRow
          label={tranlatedKey}
          value={renderDownloadLink(`customer-receipt-${transaction?.receiptNumber}`, value)}
        />
      );
    }
    if (key === 'merchantReceipt') {
      return (
        <PaperTableRow
          label={tranlatedKey}
          value={renderDownloadLink(`merchant-receipt-${transaction?.receiptNumber}`, value)}
        />
      );
    }

    const formattedValue = String(value || '-/-');
    return <PaperTableRow label={tranlatedKey} value={formattedValue} />;
  }, [renderDownloadLink, renderTableRowList, t, transaction?.receiptNumber]);

  if (!transaction?.payments?.length) return null;

  return (
    <PaperTable headline={t('paymentResult.headline')}>
      {transaction.payments?.map((payment, index) => (
        <>
          <TableRow>
            <TableCell sx={expandedPayment === index ? { borderBottom: 'none' } : {}}>
              <TextWithSubText
                text={formatPrice(payment.paidAmount, payment.currency)}
                subText={!!payment.surcharge && t('paymentResult.surcharge', { amount: formatPrice(payment.surcharge, payment.currency) })}
              />
            </TableCell>
            <TableCell sx={expandedPayment === index ? { borderBottom: 'none' } : {}}>
              <PaymentMethodIcon
                paymentMethod={payment.method}
                project={transaction.project}
                paymentCardType={payment.cardType}
              />
            </TableCell>
            <TableCell sx={expandedPayment === index ? { borderBottom: 'none' } : {}}>
              <PaymentStateIndicator payment={payment} />
            </TableCell>
            <TableCell sx={expandedPayment === index ? { borderBottom: 'none' } : {}} align="right">
              {!!payment.result && (
                <Button
                  size="small"
                  endIcon={<ExpandMoreIcon sx={expandedPayment === index ? { transform: 'rotate(180deg)' } : {}} />}
                  onClick={() => setExpandedPayment(expandedPayment === index ? null : index)}
                >
                  {expandedPayment === index ? t('orders.hideDetails') : t('orders.showDetails')}
                </Button>
              )}
            </TableCell>
          </TableRow>
          {expandedPayment === index && (
            <TableRow>
              <TableCell />
              <TableCell colSpan={3}>
                <Table sx={{ '> tr:last-child': { '> td': { borderBottom: 'none' } } }}>
                  {toPairs(flattenObject(payment.result)).map(renderTableRow)}
                </Table>
              </TableCell>
            </TableRow>
          )}
        </>
      ))}
    </PaperTable >
  );
}
